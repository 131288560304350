<template>
  <div class="products">
    <v-container>
      <v-row class="mb-8">
        <v-col
          v-for="subCategory in category.children"
          :key="subCategory.id"
          cols="6"
          sm="6"
          lg="3"
        >
          <v-card
            class="proposal-card d-flex flex-column justify-space-around align-center"
            rounded="sm"
            flat
            :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
          >
            <v-img
              :src="
                subCategory.metaData.category_info.ICON
                  ? subCategory.metaData.category_info.ICON
                  : '/no-image-grey.png'
              "
              :alt="subCategory.name"
              width="170"
              height="170"
            />
            <v-card-text
              class="pre-slide-text align-center"
              v-html="subCategory.name"
            ></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="secondary product-slider-wrapper">
      <ProductListSlider
        :layout="25"
        :categoryId="category.categoryId"
        :promo="true"
        :title="'In promozione in ' + category.name"
        :paginationClass="'swiper-pagination-promo'"
        class="secondary"
        position="category_main_i_piu_venduti"
      />
    </div>
    <div class="grey lighten-2 product-slider-wrapper">
      <ProductListSlider
        :layout="27"
        :categoryId="category.categoryId"
        :title="'I più venduti in ' + category.name"
        :paginationClass="'swiper-pagination-venduti'"
        position="category_main_in_promozione"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.center-img {
  margin-right: auto;
  margin-left: auto;
}
.pre-slide-text {
  width: initial;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #3a3a3a;
  text-transform: uppercase;
}
</style>
<script>
// @ is an alias to /src

// import ProductListSlider from "@/components/product/ProductListSlider";

import categoryMixins from "~/mixins/category";

import get from "lodash/get";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      }
    };
  },
  components: {
    // ProductListSlider
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        }
      ]
    };
  }
};
</script>
